.credit-score-container {
 background-color: #ff7300;
 color: white;
 padding: 20px;
 border-radius: 20px;
 text-align: center;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.credit-score-title {
 margin: 0;
 color: white;
}

.credit-score-value {
 font-size: 2em;
 font-weight: bold;
 color:white;
}

/* Modal.css */

.modal1 {
   
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 108%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    box-shadow: 2px 2px 4px solid black;
   }
   
   .modal1-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 40%; /* Could be more or less, depending on screen size */
   }
   
  