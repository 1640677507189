body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --title-body-small-mediumpsmallmedium: "Nunito Sans";

  /* font sizes */
  --title-body-small-mediumpsmallmedium-size: 14px;
  --caption-normal-small-size: 12px;
  --label-tags-xs-plabel-xs-size: 10px;
  --title-title-1-h3-size: 24px;

  /* Colors */
  --primary-main: #13316b;
  --mono-white: #fff;
  --neutral-2: #ccc;
  --neutral-7: #232323;
  --outline-outline: rgba(0, 0, 0, 0.12);
  --secondary-main: #fd5f07;
  --mono-white-subdued: #f4f6f8;
  --neutral-3: #aaa;
  --primary-1: #10215f;
  --text-med-emp: #666;
  --neutral-6: #444;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-11xs: 2px;
  --gap-7xs: 6px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;

  /* Paddings */
  --padding-5xs: 8px;
  --padding-5xl: 24px;
  --padding-8xs: 5px;
  --padding-7xs: 6px;
  --padding-xs: 12px;
  --padding-base: 16px;

  /* Border radiuses */
  --br-5xl: 24px;
  --br-9xs: 4px;
  --br-10xs: 3px;
  --br-11xs: 2px;

  /* Effects */
  --ele-sm: 0px 1px 0px #dee5ec, 0px 4px 12px rgba(12, 22, 32, 0.06);
}


 html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

figure {
  margin: 0;
}

/**
 * Scaffolding
 */
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: normal normal normal 1rem/1.6 -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

body {
  color: #404040;
  background: white;
  font-size: 1rem;
}

p,
ol,
ul,
dl,
table {
  margin: 0 0 1.5rem 0;
}

ul li ul {
  margin-bottom: 0;
}

ol li ol {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 1.5rem 0;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica,
    Arial, sans-serif;
  line-height: 1.2;
  color: #404040;
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child),
h4:not(:first-child),
h5:not(:first-child) {
  margin: 1.5rem 0;
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child) {
  margin-top: 2rem;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 1rem;
}

@media (min-width: 600px) {
  h1:not(:first-child),
  h2:not(:first-child),
  h3:not(:first-child) {
    margin-top: 2.5rem;
  }
  h1 {
    font-size: 2.25rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
}

a {
  color: #0366ee;
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  color: #0246a2;
  text-decoration: underline;
}

mark {
  background: #ffeea8;
  padding: 0 0.2rem;
}

blockquote {
  margin: 0 0 1.5rem 0;
  border-left: 16px solid #f0f0f0;
  padding: 0 1.5rem;
  font-size: 1.5rem;
}

blockquote cite {
  display: block;
  margin-top: 1.5rem;
  font-size: 1rem;
  text-align: right;
}

pre {
  border: 0;
  border-radius: 4px;
  background: transparent;
  padding: 1rem;
  tab-size: 2;
  color: #404040;
  font-family: Menlo, monospace;
  font-size: 14px;
  margin: 0 0 1.5rem 0;
}

pre code {
  font-family: Menlo, monospace;
  line-height: 1.2;
}

kbd {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  color: #333;
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.4;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  text-shadow: 0 1px 0 #fff;
}

:not(pre) > code {
  color: #404040;
  background: transparent;
  font-family: Menlo, monospace;
  font-size: 14px;
  padding: 0 0.2rem;
  border: 1px solid #dedede;
  border-radius: 4px;
}

hr {
  height: 0;
  border: 0;
  border-top: 1px solid #dedede;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
}

.full-container {
  max-width: 100%;
  padding: 0 1rem;
}

.container,
.small-container,
.medium-container {
  max-width: 1200px;
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
}

.small-container {
  max-width: 800px;
}

.medium-container {
  max-width: 1000px;
}

.content-section {
  padding: 30px 0;
}

@media (min-width: 600px) {
  .content-section {
    padding: 60px 0;
  }
}

/**
 * Grid
 */
.flex-small,
.flex-large {
  padding-left: 1rem;
  padding-right: 1rem;
}

.flex-row {
  margin-left: -1rem;
  margin-right: -1rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-small,
.flex-large {
  flex-basis: 100%;
  margin-bottom: 1rem;
}

/* Small screen breakpoint */
@media (min-width: 600px) {
  .flex-small {
    flex: 1;
    margin-bottom: 0;
  }
  .flex-small.half {
    flex: 0 0 calc(1 / 2 * 100%);
  }
  .flex-small.one-fourth {
    flex: 0 0 calc(1 / 4 * 100%);
  }
  .flex-small.three-fourths {
    flex: 0 0 calc(3 / 4 * 100%);
  }
  .flex-small.one-third {
    flex: 0 0 calc(1 / 3 * 100%);
  }
  .flex-small.two-thirds {
    flex: 0 0 calc(2 / 3 * 100%);
  }
}

/* Large screen breakpoint */
@media (min-width: 1000px) {
  .flex-large {
    flex: 1;
    margin-bottom: 0;
  }
  .flex-large.half {
    flex: 0 0 calc(1 / 2 * 100%);
  }
  .flex-large.one-fourth {
    flex: 0 0 calc(1 / 4 * 100%);
  }
  .flex-large.three-fourths {
    flex: 0 0 calc(3 / 4 * 100%);
  }
  .flex-large.one-third {
    flex: 0 0 calc(1 / 3 * 100%);
  }
  .flex-large.two-thirds {
    flex: 0 0 calc(2 / 3 * 100%);
  }
}

/**
 * Helpers
 */
.clearfix::before,
.clearfix::after {
  content: ' ';
  display: block;
}

.clearfix:after {
  clear: both;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.padding-top {
  padding-top: 2rem;
}

.padding-bottom {
  padding-bottom: 2rem;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.margin-top {
  margin-top: 2rem;
}

.margin-bottom {
  margin-bottom: 2rem;
}

.alternate-background {
  background: #fafafa;
  color: #404040;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

/**
 * Buttons
 */
.button,
a.button,
button,
[type='submit'],
[type='reset'],
[type='button'] {
  -webkit-appearance: none;
  display: inline-block;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica,
    Arial, sans-serif;
  font-size: 1rem;
  text-transform: none;
  padding: 0.50rem 1.25rem;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  line-height: 1;
}


.button::-moz-focus-inner,
a.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='button']::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.muted-button,
a.muted-button {
  background: transparent;
  border: 1px solid #cdcdcd;
  color: #4e4e4e;
}

.muted-button:hover,
.muted-button:focus,
.muted-button:active,
a.muted-button:hover,
a.muted-button:focus,
a.muted-button:active {
  color: #4e4e4e;
  border: 1px solid #818181;
  background: transparent;
}

.round-button,
a.round-button {
  border-radius: 40px;
}

.square-button,
a.square-button {
  border-radius: 0;
}

.full-button,
a.full-button {
  display: block;
  width: 100%;
}

/**
 * Forms
 */
[type='color'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='url'],
[type='week'],
[type='time'],
select,
textarea {
  
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 0.75rem;
  outline: none;
  background: transparent;
  font-size: 1rem;
  width: 100%;
  max-width: 100%;
  line-height: 1;
}

[type='color']:focus,
[type='color']:active,
[type='date']:focus,
[type='date']:active,
[type='datetime']:focus,
[type='datetime']:active,
[type='datetime-local']:focus,
[type='datetime-local']:active,
[type='email']:focus,
[type='email']:active,
[type='month']:focus,
[type='month']:active,
[type='number']:focus,
[type='number']:active,
[type='password']:focus,
[type='password']:active,
[type='search']:focus,
[type='search']:active,
[type='tel']:focus,
[type='tel']:active,
[type='text']:focus,
[type='text']:active,
[type='url']:focus,
[type='url']:active,
[type='week']:focus,
[type='week']:active,
[type='time']:focus,
[type='time']:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  border: 1px solid #0366ee;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 6px #8cbcfe;
}

textarea {
  overflow: auto;
  height: auto;
}

fieldset {
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 1rem;
  margin: 1.5rem 0;
}

legend {
  padding: 0 0.5rem;
  font-weight: 600;
}

select {
  color: #404040;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJCAYAAAA/33wPAAAAvklEQVQoFY2QMQqEMBBFv7ERa/EMXkGw11K8QbDXzuN4BHv7QO6ifUgj7v4UAdlVM8Uwf+b9YZJISnlqrfEUZVlinucnBGKaJgghbiHOyLyFKIoCbdvecpyReYvo/Ma2bajrGtbaC58kCdZ1RZ7nl/4/4d5EsO/7nzl7IUtodBexMMagaRrs+06JLMvcNWmaOv2W/C/TMAyD58dxROgSmvxFFMdxoOs6lliWBXEcuzokXRbRoJRyvqqqQvye+QDMDz1D6yuj9wAAAABJRU5ErkJggg==)
    right center no-repeat;
  line-height: 1;
}

select::-ms-expand {
  display: none;
}

[type='range'] {
  width: 100%;
}

label {
  font-weight: 600;
  max-width: 100%;
  display: block;
}

@media (min-width: 600px) {
  .split-form label {
    text-align: right;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
}

input.has-error,
input.has-error:hover,
input.has-error:focus,
input.has-error:active,
select.has-error,
select.has-error:hover,
select.has-error:focus,
select.has-error:active,
textarea.has-error,
textarea.has-error:hover,
textarea.has-error:focus,
textarea.has-error:active {
  border: 1px solid #d33c40;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 6px #f4cecf;
}

input.is-success,
input.is-success:hover,
input.is-success:focus,
input.is-success:active,
select.is-success,
select.is-success:hover,
select.is-success:focus,
select.is-success:active,
textarea.is-success,
textarea.is-success:hover,
textarea.is-success:focus,
textarea.is-success:active {
  border: 1px solid #29de7d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 6px #97efc0;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
  color: #9a9a9a;
}

/**
 * Tables
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}

/* thead th {
  border-bottom: 2px solid #dedede;
} */

tfoot th {
  border-top: 2px solid #dedede;
}

/* td {
  border-bottom: 1px solid #dedede;
} */

th,
td {
  text-align: left;
  padding: 0.5rem;
}

caption {
  padding: 1rem 0;
  caption-side: bottom;
  color: #ababab;
}

.striped-table tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

.contain-table {
  overflow-x: auto;
}

@media (min-width: 600px) {
  .contain-table {
    width: 100%;
  }
}
