h4,
h5,
h6 {
  font-weight: bold;
}

.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 0px !important;

}

.personalLoan {
  font-family: Nunito Sans;
  background-color: #eef5ff;
  width: 100%;
}

.main-content {
  height: 100vh !important;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #eee;

  /* scrollbar-width: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  -ms-overflow-style: none; */
}

/* .main-content::-webkit-scrollbar {
  display: none;

} */

.main-content::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scrollbar */
}

/* Firefox scrollbar */
.main-content {
  scrollbar-width: 3px;
  /* Adjust the width of the scrollbar */
  -ms-overflow-style: 3px;
  /* Adjust the width of the scrollbar */
}

/* WebKit (Chrome, Safari) scrollbar thumb */
.main-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FD5F07;
  /* Change the color of the scrollbar thumb */
}

/* Firefox scrollbar thumb */
.main-content {
  scrollbar-color: #FD5F07;
  /* Change the color of the scrollbar thumb */
}







.plFormBox {
  background-color: #ffffff;
  margin-top: 70px;
  margin-bottom: 20px;
}

.formWizard {
  background-color: #bdd4ff;
}

.personalForm {
  padding: 20px 80px;
}

.custom-input {
  width: 250px !important;
  outline: 1px solid #a1a1a1;
  outline-offset: -2px;
}

.custom-input:focus {
  outline: 1px solid #0099ff !important;
  outline-offset: -2px;
}

.form-label {
  padding-top: 0px !important;
  background-color: white;
  top: 8px !important;
}

.custom_btn {
  color: rgb(0, 0, 0);
  width: 200px;
  height: 45px;
  border-radius: 40px !important;
  border: 1px solid gray;
  margin: 0px 3px !important;
}

.orange {
  background-color: rgb(255, 136, 0);
}

.form-check-input[type=radio] {
  margin-left: 0px;
}

.form-check-label {
  padding-left: 2.15rem;
}

.small-heading {
  font-size: 11px;
  font-weight: 600;
}

.smaller-heading {
  font-size: 9px;
  font-weight: 600;
}

.msg {
  font-size: 11px;
  font-weight: 600;
  margin-left: 6px;
}

.tenure {
  padding: 20px;
  width: 90%;
  margin: 0 20px;
  border: 1px solid rgba(156, 156, 156, 0.527);
  border-radius: 10px;

}

.kyc-form-text-size {
  font-size: 13px;
}

.sanction-letter-text-size {
  font-size: 11px;
}

/* Add this CSS to your stylesheets */
.range-slider {

  /* Change the thumb color (dot) */
  &::-webkit-slider-thumb {
    background-color: orange;
  }

  &::-moz-range-thumb {
    background-color: orange;
  }

  &::-ms-thumb {
    background-color: orange;
  }

  /* Change the filled track color */
  &::-webkit-slider-runnable-track {
    background: linear-gradient(to right, orange, blue);
    /* You can customize the gradient colors */
  }

  &::-moz-range-track {
    background: linear-gradient(to right, orange, blue);
  }

  &::-ms-track {
    background: linear-gradient(to right, orange, blue);
  }
}

.upload_btn {
  font-size: 10px !important;
  font-weight: 900 !important;
  padding: 5px 10px !important;
  background-color: transparent I !important;
  border-radius: 20px !important;
  border: 1px solid rgba(139, 139, 139, 0.247) !important;
}

.check-icon {
  color: white;
  border-radius: 50%;
  padding: 3px;
  margin-top: -6px;
  margin-right: 4px;
  background-color: green;
}

.upload-lable {
  font-size: 14px;
}

.badge-icon {
  margin-top: -7px;
  margin-right: 4px;
  color: rgb(255, 187, 0);

}

.badge-icon1 {
  margin-top: -7px;
  margin-right: 4px;
  /* color: rgb(255, 187, 0); */

}

.AdharKYCheading {
  text-align: center;
}

.AdharOTPHeading {
  text-align: center;
}

.otp-input {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid grey;
}

.loginOtp-input {
  width: 40px;
  margin: 5px;
  text-align: center;
  border: 1px solid grey;
  height: 45px;
}

.uploadadhar {
  display: flex;
  justify-content: center;
  align-items: center;

}

.photoVerificationBox {
  height: 300px;
}

.upload-box {
  width: 350px;
  height: 180px;
  border-radius: 10px;
  border: 1px solid #cccccc8f;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-button {
  width: 40px;
  padding: 7px 0 0;
  border: none;
  font-size: 17px;
  border-radius: 3px;
}


.upload-label {
  cursor: pointer;
  display: block;
  font-weight: 900;
  font-size: 12px;
  margin-top: 10px;
}

.upload-icon {
  font-size: 24px;
  margin-right: 8px;
}

.clear_btn {
  box-shadow: none !important;
  text-transform: capitalize !important;
  border: 1px solid gray !important;
  color: black !important;
  width: 200px !important;
  height: 45px !important;
  border-radius: 40px !important;
  margin: 0px 3px !important;
}

.btn_white {
  text-transform: capitalize !important;
  color: black !important;

  font-size: 11px !important;
  background-color: rgb(255, 255, 255) !important;
}

.apply_btn_white {
  text-transform: capitalize !important;
  color: rgb(231, 69, 0) !important;

  font-size: 11px !important;
  background-color: rgb(255, 255, 255) !important;
}

.btn_orange {
  color: white !important;
  background-color: rgb(255, 115, 0) !important;
}

.selected-label {
  font-weight: 700;
}

.css-1hg6wbx-MuiFormControl-root-MuiTextField-root .MuiInputBase-input {
  font-size: 15px;
}

/* Hide default placeholder in date input */
.ArthaTextField input[type="date"] {
  color: transparent !important;
}

/* Show text color when input has a value */
.ArthaTextField input[type="date"]:not([value=""]) {
  color: initial !important;
}

.css-18kzc0b-MuiFormLabel-root-MuiInputLabel-root {
  background-color: white;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  background-color: white;
}

.radio-selected {
  color: orange;
}

tenure-heading {
  padding-right: 5px;
}

.stepperBox {
  display: flex;
  justify-content: center;
}

.css-n7tliy-MuiStep-root {
  padding: 0% !important;
}

@media (max-width: 767px) {
  .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    line-height: 9px !important;
  }

  .css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    margin-bottom: 6px;
  }

  .personalForm {
    padding: 20px 30px;
  }
}

/* Modal container */
.modal {
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 999;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal content */
.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  margin-top: 20px;
  width: 60%;
}

/* Close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.location-icon {
  color: #0e0e94;
  font-size: 150px !important;
}

.pin-location-icon {
  color: #0e0e94;
  font-size: 120px !important;
}

.account-aggregator-text-size {
  font-size: 15px;
}

.bank-name {
  font-size: 14px;
}

.aggregator {
  font-size: 14px;

}

.address-card {
  min-height: 73px;
}

.selected-card {
  font-weight: bold !important;
  background-color: #ff7b0025;
  border: 1.5px solid rgb(255, 81, 0);
}

.selected-offer {
  font-weight: bold !important;
  background-color: #ff7b0025;
  border: 1.5px solid rgb(255, 81, 0);
}

.add-new-btn {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.aadhar-msg {
  font-size: 11px;
  margin-left: 6px;
}

.add-new-btn {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.accept-offer-btn {
  font-size: 10px !important;
}

.offer-page-heading {
  font-weight: bold;
  font-size: 12px;
}

.loan-offer-card {
  background-color: #0072ff14;
}

.loan-offer-amount {
  font-weight: 700;
  color: #0436db;
}

.loan-offer-amount2 {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: bold;
}

.all-offer-label {
  font-size: 10px;
}

.inavlid-box {
  background-color: white;
  width: 50%;
  height: 450px;
  margin-top: 70px;
  margin-bottom: 70px;
}

.inavlid-box1 {
  background-color: white;
  width: 20%;
  height: 300px;
  margin-top: 100px;
  margin-bottom: 70px;
  z-index: 1000;
}

.failed-heading {
  margin-top: 150px;
  font-weight: bold;

}

.invalid-input {
  font-size: 12px;
  padding-top: 3px;
}
.valid-input{
  font-size: 12px;
 margin-top: 7px;
}

.offerLogos {
  height: 25px;
  width: 140px;
}

.all-offers-heading {
  font-weight: 700;
}

.all-offer-page-heading {
  font-weight: bold;
  font-size: 14px;
}

.continue-btn {

  padding: 0px 10px !important;

}

.calculate-text {
  font-size: 10px
}

.css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
  width: 38%;
}

.css-1ald77x {
  background-color: white !important;
  padding: 0 2px !important;
}

.css-129zgdc {
  background-color: white !important;
  padding: 0 2px !important;
}

.accept-offer-btn {
  font-size: 10px !important;
}

.offer-page-heading {
  font-weight: bold;
  font-size: 12px;
}

.loan-offer-card {
  background-color: #0072ff14;
}

.loan-offer-amount {
  font-weight: 700;
  color: #0436db;
}

.loan-offer-amount2 {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: bold;
}

.all-offer-label {
  font-size: 10px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input-employeer-field {
  height: 10px !important;
  margin-bottom: 10px !important;
  padding: 10px 14px 10px 10px !important;
}

.editPopupLable {
  color: #0e0e94;
  font-size: 13px;
  margin-right: 4px;
  cursor: pointer;
}

.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}


.css-1ty026z {
  flex: 1 1 auto;
  margin: 10px 10px;
}

.otpBox {
  padding: 30px;
}

/* Change the color of the radio button to orange */
input[type="radio"] {
  filter: hue-rotate(145deg);
  /* Adjust the hue to get the desired shade of orange */
}

input[type="radio"] {
  width: 20px;
  /* Adjust width */
  height: 20px;
  /* Adjust height */
}






.custom-range-slider::-webkit-slider-thumb {
  width: 6px;
  -webkit-appearance: none;
  height: 12px;
  background: rgb(225, 29, 29);
  border-radius: 2px;
}

/* .custom-range-slider::-moz-range-thumb {
  background-color: orange;
}

.custom-range-slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  color: #13bba4;
  margin-top: -1px;
} */
.css-eg0mwd-MuiSlider-thumb::after {
  background-color: #FF792F !important;
  height: 20px !important;
  width: 20px !important;
}

.circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark {
  color: white;
  font-size: 10px;
}


.itrBox {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid rgba(128, 128, 128, 0.256);
}

.msg {
  font-size: 11px;
  font-weight: 600;
  margin-left: 6px;
}

.check-icon {
  color: white;
  border-radius: 50%;
  padding: 3px;
  margin-top: -6px;
  margin-right: 4px;
  background-color: green;
}

.badge-icon {
  margin-top: -7px;
  margin-right: 4px;
  color: rgb(255, 187, 0);

}

.upload_btn {
  font-size: 10px !important;
  font-weight: 900 !important;
  padding: 5px 10px !important;
  background-color: transparent I !important;
  border-radius: 20px !important;
  border: 1px solid rgba(139, 139, 139, 0.247) !important;
}

.btn_white {
  text-transform: capitalize !important;
  color: black !important;

  font-size: 11px !important;
  background-color: rgb(255, 255, 255) !important;
}

.custom_btn {
  color: rgb(0, 0, 0);
  width: 200px;
  height: 45px;
  border-radius: 40px !important;
  border: 1px solid gray;
  margin: 0px 3px !important;
}

.orange {
  background-color: rgb(255, 136, 0);
}

.custom-disabled-input .MuiInputBase-input.Mui-disabled {
  color: rgb(110, 110, 110) !important;
  -webkit-text-fill-color: rgb(110, 110, 110) !important; /* for WebKit browsers */
  opacity: 1; /* Override the opacity */
}


.continue-button {
  color: white;
  background-color: #ff7300; /* Continue button color */
  width: 120px; /* Desired width */
  height: 45px; /* Desired height */
  display: flex;
  align-items: center;
  justify-content: center;
  border: none; /* Remove default border */
  border-radius: 25px; /* Optional: Add border-radius for rounded corners */
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.continue-button.disabled {
  background-color: gray; /* Color when disabled */
  opacity: 0.5; /* Fade-out effect */
  cursor: not-allowed; /* Change cursor when disabled */
}

