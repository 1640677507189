
.p-80{
  padding: 65px;
}

.loginForms{
  display: flex;
  justify-content: center;
  align-items: center;
}

.artha-tagline{
  font-size: 16px;
  font-weight: 800;
}

.mainDiv{
  height: 100vh;
  background-color: #13316b;
  padding: 26px;
}

.loginModule{
  background-color: #fff;
  margin-top: 80px;
  border-radius: 7px;
}

.loginBox{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-right: 1px solid #D4D4DC;
}

.welcome {
  position: relative;
  line-height: 32px;
}

.enter-details-to {
  position: relative;
  font-size: var(--title-body-small-mediumpsmallmedium-size);
  line-height: 20px;
  font-weight: 600;
  color: var(--neutral-6);
}

.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}

.group-child,
.group-item {
  position: absolute;
  border-radius: var(--br-11xs);
}

.group-child {
  top: 0;
  left: 0;
  border: 2px solid var(--text-med-emp);
  box-sizing: border-box;
  width: 12px;
  height: 19px;
}

.group-item {
  top: 15px;
  left: 4px;
  background-color: var(--text-med-emp);
  width: 4px;
  height: 1px;
}

.rectangle-parent {
  position: absolute;
  top: 2px;
  left: 6px;
  width: 12px;
  height: 19px;
}

.mobile {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.pan-card-no {
  flex: 1;
  position: relative;
  line-height: 20px;
}

.card-icon {
  position: relative;
  width: 24px;
  height: 24px;
  display: none;
}

.box,
.content {
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
}

.content {
  flex: 1;
  height: 24px;
  align-items: center;
  gap: var(--gap-5xs);
}

.box {
  align-self: stretch;
  border-radius: var(--br-10xs);
  border: 1px solid var(--outline-outline);
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-xs) var(--padding-base);
  cursor: pointer;
  z-index: 0;
  font-size: var(--title-body-small-mediumpsmallmedium-size);
}

.pan-card-no1 {
  position: relative;
  line-height: 10px;
  font-weight: 600;
  opacity: 0;
}

.label {
  margin: 0 !important;
  position: absolute;
  top: 24px;
  left: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-7xs);
  z-index: 1;
}

.otp-verification-will {
  position: relative;
  line-height: 10px;
  font-weight: 600;
}

.mobile-no,
.subtitle {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.subtitle {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  padding: 0 0 0 var(--padding-5xs);
  z-index: 2;
}

.mobile-no {
  width: 309px;
  flex-direction: column;
  padding: var(--padding-8xs) 0 0;
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-9xs);
}

.card-icon1 {
  position: relative;
  width: 24px;
  height: 24px;
}

.box1,
.subtitle1 {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.box1 {
  border-radius: var(--br-10xs);
  border: 1px solid var(--outline-outline);
  display: flex;
  padding: var(--padding-xs) var(--padding-base);
  z-index: 0;
  font-size: var(--title-body-small-mediumpsmallmedium-size);
}

.subtitle1 {
  display: none;
  padding: 0 0 0 var(--padding-5xs);
  z-index: 2;
}

.terms-conditions {
  position: relative;
  top: 1px;
  text-decoration: underline;
  line-height: 16px;
  color: #10215f !important;
}

.checkbarselected3-icon {
  position: relative;
  border-radius: var(--br-9xs);
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.checkbox2 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--neutral-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.icon {
  position: relative;
  width: 16px;
  height: 16px;
}

.lorem-ipsum-dolor {
  position: relative;
  line-height: 18px;
}

.checkbox1 {
  position: absolute;
  top: 0;
  left: calc(50% - 154.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  color: var(--neutral-7);
}

.checkbox {
  position: relative;
  width: 309px;
  height: 18px;
  font-size: var(--caption-normal-small-size);
  color: var(--primary-1);
}

.button {
  position: relative;
  line-height: 20px;
  font-weight: 800;
}

.content2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-11xs);
}

.cta,
.form {
  display: flex;
  flex-direction: column;
}

.cta {
  border-radius: var(--br-5xl);
  background-color: var(--secondary-main);
  width: 309px;
  height: 44px;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-5xl);
  box-sizing: border-box;
  opacity: 0.38;
  text-align: center;
  font-size: var(--title-body-small-mediumpsmallmedium-size);
  color: var(--mono-white-subdued);
}

.form {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--label-tags-xs-plabel-xs-size);
  color: var(--text-med-emp);
}

.login-form-new,
.logo-icon {
}

.login-form-new {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-icon {
  height: 43.34%;
  width: 70.44%;
  top: -2.25%;
  right: 14.78%;
  bottom: 58.91%;
  left: 14.78%;
  max-width: 100%;
  max-height: 100%;
}

.tagline {
  line-height: 36px;
  font-weight: 600;
}

.logo-tagline {
  font-size: 18px;
  color: var(--neutral-7);
}

.enterotp {
  text-align: center;
  font-size: 13px;
}

.otp-input {
  padding: 10px;
  /* Adjust padding to center the cursor */
  text-align: center;
  width: 40px;
  margin: 0 5px;
}

#boxContainer1 {
  border: 1px solid red;
}

.numError {
  color: red;
}

.error {
  border: 1px solid red;
  margin: 0 5px;
}

*:focus {
  outline: none !important;
}

.mobile-input {
  border: none;
}

input:focus {
  outline: none !important;
}

.invalid-input {
  color: red;
}

.checkbox input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 5px;
}

.checkbox label {
  display: inline-block;
  vertical-align: text-top;
}

.get-opt-sbmt {
  text-decoration: none;
  border-radius: var(--br-5xl);
  background-color: #fd5f07;
  width: 100%;
  height: 44px;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-5xl);
  box-sizing: border-box;
  opacity: 1;
  text-align: center;
  padding-top: 12px;
  margin-top: 5px;
  font-weight: bold;
  font-size: var(--title-body-small-mediumpsmallmedium-size);
  color:white;
  text-transform: none;
}
.get-opt-sbmt:hover{
  text-decoration: none;
  color:white;
}


.title {
  font-size: 24px;
  color: #10215f;
  position: relative;
  line-height: 32px;
}

.enter-the-6-digit {
  color: #010101;
  font-size: 14px;
  font-family: Nunito Sans !important;
}

.loginOtp-input {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.otp-inputs {
  margin: 10px 0px;
}

.resend-otp1 {
  position: relative;
  line-height: 20px;
  font-weight: 800;
  color: var(--secondary-main);
  margin: 10px 0px;
}

.verify-proceed-btn {
  color: white;
}
.verify-proceed-btn:hover {
  color: white;
}
.verify-phone-number {
  color: #10215f;
  font-size: 14px;
  margin: 5px 0px 35px;
  font-family: 'Nunito Sans !important';
  font-weight: 600;
}

.fa-edit-icon {
  font-size: 14px;
  color: #10215f;
  font-weight: 600;
}

svg {
  overflow: hidden;
  vertical-align: text-top;
}

    
@media (max-width: 767px) {
  .loginLogo {
    border: none;
  }
}
.termsPopup{
  z-index: 999 !important;
}
.loginOtp-input.error {
  border-color: red !important;
}

.terms-heading{
  color: #13316b;
}

/* ::-webkit-scrollbar {
  width: 5px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1;
}


::-webkit-scrollbar-thumb {
  background: #0011ff;
}


::-webkit-scrollbar-thumb:hover {
  background: #0011ff;
} */

 .checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border: 2px solid #333;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

/* Style the custom checkbox when checked */
.checkbox input[type="checkbox"]:checked {
  background-color: #ff9100;
  /* Change to your desired color */
  border-color: #ff9100;
  /* Change to your desired color */
}


.checkbox input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: -5%;
  left: 26%;
  transform: rotate(39deg) !important;
  transform: translate(-50%, -50%);
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  visibility: hidden;
}

/* Show the checkmark when the checkbox is checked */
.checkbox input[type="checkbox"]:checked::before {
  visibility: visible;
}