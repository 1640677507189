.hamburger-menu-icon {
  position: relative;
  width: 32px;
  height: 32px;
  display: none;
}

.logo-icon {
  width: 110.38px;
  height: 16px;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.profile-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid var(--neutral-1);
  box-sizing: border-box;
  width: 32px;
  height: 32px;
}

.rs {
  border: 1px solid white;
  border-radius: 50%;
  padding: 12px;
  position: absolute;
  top: 2px;
  left: 7.5px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 24px;
  color: aliceblue;
}

.profile {
  position: relative;
  width: 32px;
  height: 32px;
}

.content,
.profile-component {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-component {
  border-radius: var(--br-7xs);
  justify-content: flex-start;
  gap: var(--gap-11xs);
}

.content {
  align-self: stretch;
  justify-content: space-between;
}

.header {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--primary-1);
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-5xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--neutral-1);
  font-family: var(--font-inter);
}

.frame-child {
  position: relative;
  width: 45.66px;
  height: 45.75px;
  margin-right: 10px;
}

.personal-loan {
  position: relative;
  line-height: 24px;
  display: inline-block;
  width: 206.34px;
  flex-shrink: 0;
}

.group-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}

.flexible-personal-loans {
  align-self: stretch;
  position: relative;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
}

.frame-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.apply-now {
  text-decoration: none;
  position: relative;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
  color: #ffc2a0;
  font-weight: 700;
  cursor: pointer;
}

.frame-group {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--primary-4);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--padding-xl);
}

.frame-item {
  position: relative;
  width: 45.66px;
  height: 48px;
  margin-right: 10px;
}

.home-loan {
  flex: 1;
  position: relative;
  line-height: 24px;
}

.group-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}

.apply-now1,
.turn-your-homeownership {
  position: relative;

  line-height: 20px;
}

.turn-your-homeownership {
  align-self: stretch;
  font-size: 14px;
  color: var(--color-black);
}

.apply-now1 {
  color: var(--secondary-1);
}

.frame-inner,
.group-icon {
  position: relative;
  width: 48.97px;
  height: 48px;
  margin-right: 10px
}

.group-icon {
  width: 50.09px;
  margin-right: 10px
}

.frame-parent {
  margin-top: 5%;
  margin-left: 7%;
  height: 192px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}

.credit-score-checker {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}

.credit-score-checker-parent {
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  z-index: 0;
}

.check-now {
  position: relative;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
  color: var(--primary-1);
  z-index: 1;
}

.frame-child1 {
  position: absolute;
  margin: 0 !important;
  height: 62.67%;
  width: 23.89%;
  top: 18.9%;
  right: 68.4%;
  bottom: 18.43%;
  left: 7.71%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.5;
  z-index: 2;
}

.frame-parent7 {
  border-radius: var(--br-5xs);
  background-color: var(--secondary-4);
  width: 519px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-13xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-base);
}

.frame-child2 {
  position: absolute;
  margin: 0 !important;
  height: 56.17%;
  width: 15.55%;
  top: 21.95%;
  right: 72.12%;
  bottom: 21.88%;
  left: 12.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.5;
  z-index: 1;
}

.check-now1 {
  position: absolute;
  top: 112px;
  text-decoration: none;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
  color: #10215f;
  font-weight: 700;
  z-index: 2;
}

.check-now1:hover {
  position: absolute;
  top: 112px;
  text-decoration: none;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
  color: #10215f;
  font-weight: 700;
  z-index: 2;
}

.frame-parent6 {
  position: absolute;
  top: 608px;
  left: calc(50% - 491px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}

.credit-score,
.get-the-right {
  margin-top: 10%;
  margin-left: 40%;
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  color: var(--primary-1);
}

.credit-score {
  bottom: 332px;
  left: calc(50% - 119px);
  font-size: 24px;
  font-weight: 600;
}

.chev1-right-icon,
.home-icon {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 10px;

}

.home-icon {
  overflow: hidden;
  flex-shrink: 0;
}

.chev1-right-icon {
  display: none;
}




.loan-types-icon {
  position: relative;
  width: 24px;
  height: 24px;
}


.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the alpha value for the desired transparency */
  backdrop-filter: blur(0px);
  /* Adjust the blur value for the desired effect */
  z-index: 1;
  /* Ensure the backdrop is above other elements */
}


.failed-subheading {
  font-size: 13px;
  font-weight: bold;
}

.main-header {
  z-index: 999;
}


.typesofloan {
  display: none;
}

.typesofloan.show {
  display: block;
}

.greater-than-icon:hover {
  filter: brightness(0) invert(1);
}