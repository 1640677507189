.drawer {
    margin: -14px -10px;
    width: auto;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    z-index: 999;
    justify-content: flex-start;
    box-sizing: border-box;
    background-color: white;
    font-size: var(--title-body-large-p-size);
    color: var(--neutral-6);
    transition: width 0.5s;
    /* Add transition for smooth width change */
    position: absolute;
    top: 65px;
    left: 10px;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    font-size: var(--title-body-large-p-size);
    color: var(--neutral-6);
    transition: 0.3s;
    z-index: 100;
}

.active {
    background-color: blue;
}

.left-nav-tab {
    background-color: var(--primary-2);
    color: var(--neutral-1);
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-xl) var(--padding-base) var(--padding-xl) var(--padding-5xl);
    gap: var(--gap-5xl);
    transition: color 0.5s;
    /* Add transition for smooth color change */

    z-index: 1;
    color: var(--neutral-1);
    height: 55px;
    margin: 5px;

    border-bottom: 1px solid var(--outline-outline);
    box-sizing: border-box;
    width: 200px !important;
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-xl) var(--padding-base) var(--padding-xl) var(--padding-5xl);
    gap: var(--gap-5xl);

}

.left-nav-tab:hover,
.left-nav-tab:hover .dropdown-toggle {
    background-color: #007bff;
    /* Blue background color */
    color: #ffffff;
    /* White text color */
}

.left-nav-tab:hover,
.left-nav-tab:active,
.left-nav-tab:focus {
    background-color: #1c4494;
    color: white;
}

.left-nav-tab:hover .home-icon,
.left-nav-tab:active .home-icon,
.left-nav-tab:focus .home-icon {
    filter: brightness(0) invert(1);
    color: white !important;
    /* Add !important to ensure it overrides other styles */
}

.left-nav-tab .home-icon {
    transition: margin-left 0.5s;
    /* Add transition for smooth margin-left change */
}

.left-nav-tab .home-loan {
    margin-left: -20px;
    /* Adjust the margin to hide the label initially */
}




.active-link {
    background-color: blue;
    /* Change the background color to your preference */
    color: white;
    /* Change the text color to your preference */
    font-weight: bold;
    /* Add any additional styles for the active link */
}

.drawer {
    height: 100vh;
    background-color: white;
    margin: -14px -10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    font-size: var(--title-body-large-p-size);
    color: var(--neutral-6);
    transition: 0.3s;
    box-shadow: -4px 2px 10px black;
}

.drawer:hover {
    transition: 0.3s;
    width: 200px !important;
}

.left-nav {
    display: flex;
    flex-direction: column;
}

.left-nav-tab:hover {
    text-decoration: none;
}

.left-nav-tab {
    background-color: white;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    color: var(--neutral-1);
    /* height: 55px; */
    margin: 0px;
    border: 1px solid rgba(128, 128, 128, 0.301);
}

.active {
    background-color: #1C4494;
}

.active .home-icon {
    filter: brightness(0) invert(1);
    color: white !important;
    /* Add !important to ensure it overrides other styles */
}

.home-icon {
    width: 30px;
    height: 20px;
    margin: 10px;

    overflow: hidden;
    flex-shrink: 0;
}

.home-loan {
    color: white;
}

.loan-tab {
    position: relative;
}

.greater-than-icon {
    margin-left: 10px;
    margin-right: 10px;
}

.loan-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #20232a;
    padding: 10px;
    display: none;
}



.active-link {
    color: white;
}

.header {
    z-index: 999;
}

.dashBody {
    background-color: #ffffff;
    height: 100vh;
}

.mainBody {
    margin-left: -30px;
}

.arrows {
    margin: 0px 5px;
}

.arrowsDown {
    margin: 0px 5px;
    transform: rotate(180deg);
}

.submenu-item:hover {
    background-color: #cecece;
    /* Change to the desired background color */
}

.label {
    font-size: 14px;
}

.submenu {
    transition: opacity 0.5s ease;
}