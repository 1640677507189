.hamburger-menu-icon {
  position: relative;
  width: 32px;
  height: 32px;
  display: none;
}

.logo-icon {
  position: relative;
  width: 110.38px;
  height: 16px;
}

.mainLogo {
  width: 244px;
  height: 60px;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.profile-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid var(--neutral-1);
  box-sizing: border-box;
  width: 32px;
  height: 32px;
}

.rs {
  border: 1px solid white;
  border-radius: 50%;
  padding: 12px;
  position: absolute;
  top: 2px;
  left: 7.5px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 24px;
  color: aliceblue;
}

.profile {
  position: relative;
  width: 32px;
  height: 32px;
}

.content,
.profile-component {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-component {
  border-radius: var(--br-7xs);
  justify-content: flex-start;
  gap: var(--gap-11xs);
}

.content {
  align-self: stretch;
  justify-content: space-between;
}

.header {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--primary-1);
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-5xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--neutral-1);
  font-family: var(--font-inter);
}

.frame-child {
  position: relative;
  width: 45.66px;
  height: 45.75px;
}

.personal-loan {
  position: relative;
  line-height: 24px;
  display: inline-block;
  width: 206.34px;
  flex-shrink: 0;
}

.group-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}

.flexible-personal-loans {
  align-self: stretch;
  position: relative;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
}

.frame-container {
  align-self: stretch;
  background-color: #bdd4ff;
  width: 300px;
  border-radius: 10px;
  padding: 15px;
  height: 174px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.apply-now {
  position: relative;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
  color: #cc4b03;
  cursor: pointer;
  font-weight: 700;
}

.apply-now:hover {
  position: relative;
  text-decoration: none;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
  color: #cc4b03;
  cursor: pointer;
  font-weight: 700;
}

.frame-group {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--primary-4);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--padding-xl);
}

.frame-item {
  position: relative;
  width: 45.66px;
  height: 48px;
}

.home-loan {
  flex: 1;
  position: relative;
  line-height: 24px;
}

.group-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}

.apply-now1,
.turn-your-homeownership {
  position: relative;

  line-height: 20px;
}

.turn-your-homeownership {
  align-self: stretch;
  font-size: 14px;
  color: var(--color-black);
}

.apply-now1 {
  color: var(--secondary-1);
}

.frame-inner,
.group-icon {
  position: relative;
  width: 48.97px;
  height: 48px;
}

.group-icon {
  width: 50.09px;
}

.credit-score-checker {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}

.credit-score-checker-parent {
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  z-index: 0;
}

.check-now {
  position: relative;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
  color: var(--primary-1);
  z-index: 1;
}

.frame-child1 {
  position: absolute;
  margin: 0 !important;
  height: 62.67%;
  width: 23.89%;
  top: 18.9%;
  right: 68.4%;
  bottom: 18.43%;
  left: 7.71%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.5;
  z-index: 2;
}

.frame-parent7 {
  border-radius: var(--br-5xs);
  background-color: #ffc2a0;
  width: 450px;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-base);
}

.frame-child2 {
  position: absolute;
  margin: 0 !important;
  height: 56.17%;
  width: 15.55%;
  top: 21.95%;
  right: 72.12%;
  bottom: 21.88%;
  left: 12.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.5;
  z-index: 1;
}

.check-now1 {
  position: absolute;
  top: 112px;
  text-decoration: none;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
  color: #10215f;
  font-weight: 700;
  z-index: 2;
}

.check-now1:hover {
  position: absolute;
  top: 112px;
  text-decoration: none;
  font-size: var(--title-body-small-bold-psmall-bold-size);
  line-height: 20px;
  color: #10215f;
  font-weight: 700;
  z-index: 2;
}

.frame-parent6 {
  position: absolute;
  top: 500px;
  left: calc(50% - 491px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}


.credit-score {
  position: absolute;
  top: 450px;
  left: calc(50% - 193px);
  font-size: 32px;
  line-height: 32px;
  color: var(--primary-1);
}

.credit-score {
  bottom: 332px;
  left: calc(50% - 119px);
  font-size: 24px;
}

.drawer {
  height: 90vh !important;
}

.drawer-child {
  position: absolute;
  margin: 0 !important;
  top: 250px;
  left: -1px;
  background-color: var(--mono-white);
  width: 305px;
  height: 200px;
  z-index: 0;
}

.chev1-right-icon,
.home-icon {
  position: relative;
  width: 30px;
  height: 20px;
  margin: 10px;

}

.home-icon {
  overflow: hidden;
  flex-shrink: 0;
}

.chev1-right-icon {
  display: none;
}


.web-13 {
  position: relative;
  background-color: #eef5ff;
  width: 100%;
  text-align: left;
  font-size: var(--title-title-3-h5-size);
  color: var(--neutral-7);
  font-family: var(--title-body-small-bold-psmall-bold);
}



.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  cursor: pointer;
  /* Add this line to make the backdrop clickable */
}

.blurred {
  filter: blur(5px);
}


.main-header {
  z-index: 999;
}

.dashboardHeader2 {
  display: block;
  border-radius: 10px;
  padding: 10px 10px;
  background-color: white;
}

.dashboardmsg {
  font-size: 12px;
  margin: 0px !important;
}

.loanBoxes {
  margin-right: 10px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid gray;
}

.LoanHeading {
  font-weight: bold;
  font-size: 12px;
  color: #1C4494;
  text-align: center;
  margin-left: 6px;
}

.loandiscription {
  font-size: 10px;
  padding: 2px 5px;
}

.offer {
  color: #1C4494;
  font-size: 14px;
  font-weight: bold;
}

.apply {
  color: #ff3c00;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
}

.apply:hover {
  color: #ff3c00;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
}

.eligibilityCheck {
  margin-left: -27px !important;
}

.loanSection {
  padding: 5px 10px;
  background-color: white;
  border-radius: 10px;
  padding-bottom: 10px;

}

.loanMsg {
  font-weight: bold;
  font-size: 14px;
}

.creditScore {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

.creditscore-subheading {
  font-size: 12px;
}

.creditscore-subheading2 {
  font-size: 10px;
  text-align: center;
}

/* .myAppSection {
  padding: 10%;
  margin-top: -8%;
} */
.myAppSection {
  border-radius: 10px;
}

.myAppSection #tabs {
  background: #007b5e;
  color: #eee;
}

.myAppSection #tabs h6.section-title {
  color: #eee;
}

.main-header {
  z-index: 999;
}

.myAppSection #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  text-align: left;

  color: #000000;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 3px solid rgb(209, 59, 22) !important;
  padding: 5px 0px !important;
  font-size: 14px;
  text-transform: capitalize;
}

.nav-link {
  border: 1px solid transparent;
  text-align: left;
  padding: 5px 0px !important;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  color: #000000;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 600;
}

.nav-link:hover {
  color: #000000;
}

thead {
  background: #f3f3f3;
  color: #333;
}

a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
}

.myapplicationthead {
  font-size: 12px;
}

.myAppSection {
  background-color: #BDD4FF;
}

.myapplicationTable tbody,
td,
tfoot,
th,
thead,
tr {
  font-size: 12px;
  width: 120px;
  background-color: white;

}

.ongoingApplications {
  display: none;
}

.pastApplication {
  display: none;
}



.dashboardHeader3 {
  margin: 10px;
  display: block;
  border-radius: 10px;
  border: 1px solid gray;
  padding: 10px 10px;
  background-color: white;
}

.myLoanSection {
  border-radius: 10px;
  background-color: white;
}

.myapplicationTable {
  width: 100%;
  table-layout: fixed;
}

.myapplicationTable th,
.myapplicationTable td {
  /* width: 20%; */
  text-align: left;
  padding: 8px;

}

.offerImages {
  width: 95%;
  margin: 5px;
}

.dashContainer {
  margin-left: 5%;
}

.nav-item.nav-link.active {
  cursor: pointer;
  font-weight: bold;
  border-bottom: 2px solid #FD5F07;

}

.offerImages {
  width: 95%;
  margin: 5px;
}

.viewDetails {
  text-transform: capitalize;
  width: auto;
  font-size: 14px;
  color: #FD5F07;
  cursor: pointer;
}

.noScroll {
  overflow-x: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.noScroll::-webkit-scrollbar {
  display: none;

}

.showcontent {
  height: 269px;
  overflow-y: scroll;
  display: block;
}

/* WebKit (Chrome, Safari) scrollbar */
.showcontent::-webkit-scrollbar {
  width: 6px;
  /* Adjust the width of the scrollbar */
}

/* Firefox scrollbar */
.showcontent {
  scrollbar-width: 3px;
  /* Adjust the width of the scrollbar */
  -ms-overflow-style: 3px;
  /* Adjust the width of the scrollbar */
}

/* WebKit (Chrome, Safari) scrollbar thumb */
.showcontent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FD5F07;
  /* Change the color of the scrollbar thumb */
}

/* Firefox scrollbar thumb */
.showcontent {
  scrollbar-color: #FD5F07;
  /* Change the color of the scrollbar thumb */
}


.orangeArrow path {
  fill: orange !important;
}

.smallLable {
  font-size: 12px;
}

.smalltabheading {
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
}

.showEligibiltyCheckBox {
  display: block;
}

.hideEligibilityCheckBox {
  display: none;
}

.eligibilityActive {
  font-size: 14px;
  margin-right: 10px;
  border-bottom: 1px solid #FD5F07;
  font-weight: 900;
  cursor: pointer;
}

.rsSymbol {
  font-size: 24px;
  color: #28A745;
}


.rightScroll {
  position: absolute;
  top: 40%;
  right: 0;
  width: 30px;
  height: 30px;
  opacity: 0.50;
}

.leftScroll {
  position: absolute;
  top: 40%;
  left: 0px;
  width: 30px;
  height: 30px;
  z-index: 1;
  opacity: 0.50;
}

.disabled {
  opacity: 0.5; /* You can adjust the opacity to visually indicate the button is disabled */
  pointer-events: none; /* Disable pointer events so the button cannot be clicked */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  color: lightgray;
}
